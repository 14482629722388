// components
import SvgIconStyle from '../../../components/SvgIconStyle';

// ----------------------------------------------------------------------

const getIcon = (name) => <SvgIconStyle src={`/assets/icons/navbar/${name}.svg`} sx={{ width: 1, height: 1 }} />;

const ICONS = {
  user: getIcon('ic_user'),
  ecommerce: getIcon('ic_ecommerce'),
  analytics: getIcon('ic_analytics'),
  dashboard: getIcon('ic_dashboard'),
  booking: getIcon('ic_booking'),
  banking: getIcon('ic_banking')
};

const navConfig = [
  // GENERAL
  // ----------------------------------------------------------------------
  {
    subheader: 'ERT Credit Admin Dashboard',
    items: [
      { title: 'Deliver ERC Pakcage', path: '/dashboard/deliver', icon: ICONS.booking },
      { title: 'Record Payments', path: '/dashboard/record-payments', icon: ICONS.banking },
      { title: 'In Process', path: '/dashboard/two', icon: ICONS.booking }
    ],
  },

  // MANAGEMENT
  // ----------------------------------------------------------------------
  /* {
    subheader: 'management',
    items: [
      {
        title: 'user',
        path: '/dashboard/user',
        icon: ICONS.user,
        children: [
          { title: 'Four', path: '/dashboard/user/four' },
          { title: 'Five', path: '/dashboard/user/five' },
          { title: 'Six', path: '/dashboard/user/six' },
        ],
      },
    ],
  },*/
];

export default navConfig;
