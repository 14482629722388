import { useContext, useState } from 'react';
// @mui
import { styled } from '@mui/material/styles';
import {
  Input,
  Slide,
  Button,
  InputAdornment,
  ClickAwayListener,
  Autocomplete,
  TextField,
  Chip
} from '@mui/material';
// utils
import cssStyles from '../../../utils/cssStyles';
// components
import Iconify from '../../../components/Iconify';
import { IconButtonAnimate } from '../../../components/animate';
import Base from "../../../components/airtable";
import SearchContext from 'src/contexts/SearchContext';

// ----------------------------------------------------------------------

const APPBAR_MOBILE = 64;
const APPBAR_DESKTOP = 92;

const SearchbarStyle = styled('div')(({ theme }) => ({
  ...cssStyles(theme).bgBlur(),
  top: 0,
  left: 0,
  zIndex: 99,
  width: '100%',
  display: 'flex',
  position: 'absolute',
  alignItems: 'center',
  height: APPBAR_MOBILE,
  padding: theme.spacing(0, 3),
  boxShadow: theme.customShadows.z8,
  [theme.breakpoints.up('md')]: {
    height: APPBAR_DESKTOP,
    padding: theme.spacing(0, 5),
  },
}));

// ----------------------------------------------------------------------

export default function Searchbar() {
  const [isOpen, setOpen] = useState(false);
  //const [value, setValue] = useState([]);
  const [options, setOptions] = useState([]);
  const [fetchingData, setFetchingData] = useState(false);
  const { searchContext, updateSearchContext } = useContext(SearchContext);

  const handleOpen = () => {
    setOpen((prev) => !prev);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const onChangeHandler = (event, newValue) => {
    //setValue(newValue);
    if (newValue && newValue.length > 0) {
      console.log("Updating searchContext");
      updateSearchContext(newValue);
    }
  }

  const deleteItemFromSearch = (recordId) => {
    var filtered = searchContext.filter((option) => (option.recordId !== recordId));
    updateSearchContext(filtered);
  }

  var newOptions = null;
  const onInputChangeHandler = (event, newValue) => {
    if (newValue.length >= 3 && !fetchingData) {
      setFetchingData(true);
      newOptions = [];
      var searchFilter = "SEARCH('" + newValue.toLowerCase() + "', LOWER({Business Name}))";
      Base('Questionaire').select({
        // Selecting the first 3 records in Grid view:
        maxRecords: 7,
        view: "Grid view",
        fields: [
          "Business Name",
          "First Name",
          "Last Name"
        ],
        filterByFormula: searchFilter,
        sort: [{ field: "Business Name", direction: "asc" }]
      }).eachPage(function page(records, fetchNextPage) {
        // This function (`page`) will get called for each page of records.

        records.forEach(function (record) {
          newOptions.push({
            businessName: record.get("Business Name"),
            contactName: record.get("First Name") + " " + record.get("Last Name"),
            recordId: record.id
          })
        });

        // To fetch the next page of records, call `fetchNextPage`.
        // If there are more records, `page` will get called again.
        // If there are no more records, `done` will get called.
        fetchNextPage();

      }, function done(err) {
        if (err) {
          console.error(err);
          return;
        }
        setFetchingData(false);
        setOptions(newOptions);
      });
    }
    else if (newValue.length === 0) {
      setOptions([]);
    }
  }

  return (
    <ClickAwayListener onClickAway={handleClose}>
      <div>
        {!isOpen && (
          <IconButtonAnimate onClick={handleOpen}>
            <Iconify icon={'eva:search-fill'} width={20} height={20} />
          </IconButtonAnimate>
        )}

        <Slide direction="down" in={isOpen} mountOnEnter unmountOnExit>
          <SearchbarStyle>
            <Autocomplete
              getOptionLabel={(option) =>
                typeof option === 'string' ? option : option.businessName + " " + option.contactName
              }
              multiple
              limitTags={5}
              autoFocus
              fullWidth
              placeholder="Search…"
              sx={{ mr: 1, fontWeight: 'fontWeightBold' }}
              filterOptions={(x) => x}
              autoComplete
              options={options}
              onChange={onChangeHandler}
              onInputChange={onInputChangeHandler}
              renderTags={(value, getTagProps) => {
                return value.map((option, index) => (
                  <Chip {...getTagProps({ index })} 
                  onDelete={() => (deleteItemFromSearch(`${option.recordId}`))}
                  label={option.businessName} />
                ));
              }}
              value={searchContext}
              renderInput={(params) => (
                <TextField
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <Iconify
                          icon={'eva:search-fill'}
                          sx={{ color: 'text.disabled', width: 20, height: 20 }}
                        />
                      </InputAdornment>
                    ),
                  }}

                  {...params}
                  label="Search" variant="standard" />
              )}
            />

            <Button variant="contained" onClick={handleClose}>
              Search
            </Button>

          </SearchbarStyle>
        </Slide>
      </div>
    </ClickAwayListener>
  );
}
