// routes
import Router from './routes';
// theme
import ThemeProvider from './theme';
// components
import ThemeSettings from './components/settings';
import ScrollToTop from './components/ScrollToTop';
import { ProgressBarStyle } from './components/ProgressBar';
import MotionLazyContainer from './components/animate/MotionLazyContainer';
import SearchContext from './contexts/SearchContext';
import UserContext from './contexts/UserContext';
import { useState } from 'react';
// ----------------------------------------------------------------------

export default function App() {
  const [searchContext, updateSearchContext] = useState([]);
  const [userContext, updateUserContext] = useState(null);
  return (
    <MotionLazyContainer>
      <ThemeProvider>
        <ThemeSettings>
          <ProgressBarStyle />
          <ScrollToTop />
          <UserContext.Provider value={{ userContext, updateUserContext }}>
            <SearchContext.Provider value={{ searchContext, updateSearchContext }}>
              <Router />
            </SearchContext.Provider>
          </UserContext.Provider>
        </ThemeSettings>
      </ThemeProvider>
    </MotionLazyContainer>
  );
}
