// @mui
import { Stack, Button, Typography } from '@mui/material';
// assets
import { DocIllustration } from '../../../assets';
import UserContext from 'src/contexts/UserContext';
import { useContext } from 'react';
import { auth } from '../../../components/firebase/firebase';
import { useState } from 'react';
import { useEffect } from 'react';
// ----------------------------------------------------------------------

export default function NavbarDocs() {
  const { userContext } = useContext(UserContext);
  const { user, setUser } = useState(null);

  useEffect(() => {
    if (auth.currentUser !== null) {
      setUser(auth.currentUser);
    }
  }, []);

  return (
    <Stack spacing={3} sx={{ px: 5, pb: 5, mt: 10, width: 1, textAlign: 'center', display: 'block' }}>
      <DocIllustration sx={{ width: 1 }} />

      <div>
        <Typography gutterBottom variant="subtitle1">
          Hi {user ? user.displayName : "Stranger"}
        </Typography>
        <Typography variant="body2" sx={{ color: 'text.secondary' }}>
          Need help?
          <br /> Please check our docs
        </Typography>
      </div>

      <Button variant="contained">Documentation</Button>
    </Stack>
  );
}
