import { initializeApp } from "firebase/app";
import { getStorage } from "firebase/storage";
import { getAuth, signInWithPopup, signInWithRedirect, GoogleAuthProvider } from "firebase/auth";

const firebaseConfig = {
    apiKey: "AIzaSyATLoZhFFUj5_aFTvT7jiF11IqFkfKIkX4",
    authDomain: "ertc-website-v2-a2.firebaseapp.com",
    projectId: "ertc-website-v2-a2",
    storageBucket: "ertc-website-v2-a2.appspot.com",
    messagingSenderId: "825350724847",
    appId: "1:825350724847:web:34e334dc8a70bd3d4bcf1a",
    measurementId: "G-4P7HBHCBSK"
};

const app = initializeApp(firebaseConfig);
const storage = getStorage(app);
const auth = getAuth(app);
const provider = new GoogleAuthProvider();

// autologin
function autoLogin() {
    signInWithPopup(auth, provider)
        .then((result) => {
            // This gives you a Google Access Token. You can use it to access the Google API.
            const credential = GoogleAuthProvider.credentialFromResult(result);
            const token = credential.accessToken;
            // The signed-in user info.
            const user = result.user;
            console.log(user);
            // ...
        }).catch((error) => {
            // Handle Errors here.
            console.error(error)
            const errorCode = error.code;
            const errorMessage = error.message;
            // The email of the user's account used.
            const email = error.customData.email;
            // The AuthCredential type that was used.
            const credential = GoogleAuthProvider.credentialFromError(error);
            // ...
        });
}



export default app;
export { storage, auth, autoLogin };
